var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"font ajust-margin",staticStyle:{"margin-top":"80px","margin-right":"95px","margin-left":"95px"}},[_c('div',{staticStyle:{"background":"#FFFFFF","box-shadow":"0px 10px 10px rgba(226, 236, 249, 0.5)","border-radius":"30px"}},[_vm._m(0),_c('ClienteFormVue',{on:{"added-client":(clientId) => {
				this.$router.push(
					'/novoRepresentante' + `?clientId=${clientId}`
				);
			},"cancel":() => {
				this.$router.push('/clientes');
			}}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"padding-top":"28px","margin-left":"38px","margin-right":"38px","margin-bottom":"10px"}},[_c('p',{staticClass:"title"},[_vm._v("Novo Cliente")]),_c('div',{staticClass:"div-botoes"},[_c('button',{staticClass:"dark-button light-text",staticStyle:{"width":"150px","background":"#6DA1B5","border-radius":"8px 0px 0px 8px"}},[_vm._v(" DADOS DA EMPRESA ")]),_c('button',{staticClass:"light-button dark-text",staticStyle:{"width":"150px","background":"#F9FBFF","border":"1px solid #E7E7E7","border-radius":"0px 8px 8px 0px"}},[_vm._v(" REPRESENTANTES ")])])])
}]

export { render, staticRenderFns }